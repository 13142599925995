html {
    height: 100%;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    color: #363636;
}

body {
    height: 100%;
    padding: 0;
    margin: 0;
}

h1 {
    font-weight: 400;
}

p {
    font-size: 14px;
    font-weight: 400;
    padding: 0;
    margin: 0;
}

#root {
    height: 100%;
    display: flex;
    flex-direction: column;
}

input.hideArrow::-webkit-outer-spin-button,
input.hideArrow::-webkit-inner-spin-button,
.hideArrow input::-webkit-outer-spin-button,
.hideArrow input::-webkit-inner-spin-button{
    -webkit-appearance: none;
    margin: 0;
}
input.hideArrow[type=number],.hideArrow input[type=number] {
    -moz-appearance: textfield;
}

.noDrag, .noDrag * {
    user-drag: none;
    -webkit-user-drag: none;
}

.noScrollbar::-webkit-scrollbar {
    display: none;
}

.noScrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.ffInter {
    font-family: 'Inter', sans-serif;
}
